import jQuery from 'jquery';

window.$ = jQuery;
window.jQuery = jQuery;
import angular from 'angular';

window.angular = angular;

require('./vendor/SweetAlert');
require('./vendor/sweetalert.min');
require('./vendor/airlst-sdk-1.1.1');
require('angular-sanitize');
require('./vendor/angular-locale_de-de');

require('./airlst.js');

angular.module('app', [
    'oitozero.ngSweetAlert',
    'AirLST-SDK',
    'ngSanitize'
])
    .directive('ngEnter', [
        function () {
            return function (scope, element, attrs) {
                element.bind("keydown keypress", function (event) {
                    if (event.which === 13) {
                        scope.$apply(function () {
                            scope.vm.checkCode();
                        });

                        event.preventDefault();
                    }
                });
            };
        }
    ])
    .controller('AirLSTCtrl', ['$scope', '$http', '$location', 'SweetAlert', '$document', 'AirLSTSdkService', '$sce', '$q', '$window', '$timeout','$filter', function ($scope, $http, $location, SweetAlert, $document, AirLSTSdkService, $sce, $q, $window, $timeout, $filter) {
        var vm = this;

        vm.hasError = false;
        vm.loading = true;
        vm.submitting = false;
        vm.currentView = "code";
        vm.tab = "";
        vm.lightbox = 0;
        vm.companion = false;

        /**
         *
         * Tabs for navigation
         */

        // multi step form for REGISTRATION

        vm.formParams = {};
        vm.formValidationReg = false;

        vm.openDataPrivacy = function () {
            this.overlay = true;
            this.dataPrivacy = true;
        }

        vm.openImprint = function () {
            this.overlay = true;
            this.imprint = true;
        }

        vm.closeOverlay = function () {
            this.overlay = false;
            this.dataPrivacy = false;
            this.imprint = false;
        }


        vm.setTab = function (tabId) {
            vm.tab = tabId;
            vm.currentView = 'content';
            $scope.$applyAsync();
        };
        vm.isSet = function (tabId) {
            return vm.tab === tabId;
            $scope.$applyAsync();
        };

        vm.setTabSub = function (tabId) {
            // $document.scrollToElementAnimated(anmeldung);
            vm.tabSub = tabId;
        };
        vm.isSetSub = function (tabId) {
            return vm.tabSub === tabId;
        };

        vm.setTabSub2 = function (tabId) {
            // $document.scrollToElementAnimated(anmeldung);
            vm.tabSub2 = tabId;
        };
        vm.isSetSub2 = function (tabId) {
            return vm.tabSub2 === tabId;
        };


        /**
         * Initializes the application and loads all necessary data from the server
         *
         * @private
         */
        vm._init = function () {
            AirLSTSdkService.companyUid = 'TX499AM';
            AirLSTSdkService.guestlistUid = 'HR0P77VXJA';
            AirLSTSdkService.apiUrl = 'https://v1.api.airlst.com/lp';

            vm._resetRsvpInformation();
            vm._resetRsvpCode();
            vm._resetAnswerType();
            vm._initCountDown();
            vm._retrieveEventInformation();
        };

        // Public functions

        vm.checkCode = function () {
            vm.rsvpInformation = {
                contact: {},
                rsvp: {},
            };

            // vm.rsvpCode = $filter('uppercase')(vm.rsvpCode);

            switch (vm.rsvpCode) {
                // case 'PRIME':
                //     // vm.passwordCode = true;
                //     vm.hasCode = true;
                //     vm.rsvpInformation.rsvp.status = 'invited';
                //     vm.goToView('content');
                //     vm.setTab('registration');
                //     vm.rsvpInformation.contact.custom_4 = 'Prime';
                //     break;
                // case 'MÖÖÖP':
                //     // vm.passwordCode = true;
                //     vm.hasCode = true;
                //     vm.rsvpInformation.rsvp.status = 'invited';
                //     vm.goToView('content');
                //     vm.setTab('registration');
                //     vm.rsvpInformation.contact.custom_4 = 'Möööp';
                //     break;
                // case 'DÖDEL':
                //     // vm.passwordCode = true;
                //     vm.hasCode = true;
                //     vm.rsvpInformation.rsvp.status = 'invited';
                //     vm.goToView('content');
                //     vm.setTab('registration');
                //     vm.rsvpInformation.contact.custom_4 = 'Dödel';
                //     break;
                case 'hexhex':
                    // vm.passwordCode = true;
                    vm.hasCode = true;
                    vm.rsvpInformation.rsvp.status = 'invited';
                    vm.goToView('content');
                    vm.setTab('registration');
                    vm.rsvpInformation.contact.custom_4 = 'hexhex';
                    break;

                default:
                    vm.loadRsvp();
                    break;
            }
        };


        /**
         * Queries the airlst server for a rsvp
         *
         * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. If the API responds with an rsvp,
         * the 'content' view will be loaded next.
         *
         * If an error occurs during the API call, rsvp information is reset an error message will be displayed.
         *
         * @returns {PromiseLike<T | never> | Promise<T | never> | *}
         */
        vm.loadRsvp = function () {
            vm.loading = true;
            return AirLSTSdkService.retrieveRsvpInformation(vm.rsvpCode).then(function (rsvpInfo) {
                vm.rsvpInformation = vm._prepareRsvpInformationFromApi(rsvpInfo);

                vm.loading = false;


                switch (vm._checkRsvpActionOnUrl()) {
                    case 'cancel':
                        vm.cancelRsvp();
                        break;
                    default:
                        vm.goToView('content');
                        vm.setTab('registration')
                        vm.showForm = '';
                        break;
                }
            }, function (error) {
                vm.loading = false;
                vm.hasError = true;
                vm._resetRsvpInformation();

                switch (error.identifier) {
                    case 'rsvp_not_found':
                        SweetAlert.swal({
                            html:true,
                            title:'Code nicht gefunden',
                            text: "Bitte wenden Sie sich an Ihren Ansprechpartner.",
                            confirmButtonText: 'OK',
                            customClass: 'cancel-alert',
                            animation: false
                        });
                        break;
                    case 're_login_not_allowed':
                        SweetAlert.swal({
                            html:true,
                            title:'Code nicht gefunden',
                            text: "Bitte wenden Sie sich an Ihren Ansprechpartner.",
                            confirmButtonText: 'OK',
                            customClass: 'cancel-alert',
                            animation: false
                        });
                        break;
                    default:
                        SweetAlert.swal({
                            html:true,
                            title:'Fehler',
                            text: "Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.",
                            confirmButtonText: 'OK',
                            customClass: 'cancel-alert',
                            animation: false
                        });
                        break;
                }
            });
        };

        /**
         * Cancels a rsvp
         *
         * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
         * pass validation.
         *
         * After successful cancellation, the form is reset, a success message is displayed and the 'code' view will
         * be shown next.
         *
         * If an error occurs during the API call an error message is displayed.
         *
         * @param form
         */
        vm.cancelRsvp = function (form) {
            console.log("calcel");
            if (form && !vm._validateRegistrationForm(form)) {
                return;
            }

            vm.submitting = true;
            AirLSTSdkService.updateRsvpByCodeAndStatus(vm.rsvpCode, 'cancelled', vm._prepareRsvpInformationForApi(), 'cancelled').then(function (rsvpInfo) {
                // vm._init();
                vm.submitting = false;

                // SweetAlert.swal('Ihre Absage', 'Schade, dass Die nicht am Event für unsere erfolgreichsten Prohyp-Partner in Hamburg teilnehmen können.', 'success');

                vm.goToView('content');
                vm.setTab('done');
                vm.stage = 0;
            }, function (error) {
                vm.hasError = true;
                vm.submitting = false;

                switch (error.identifier) {
                    case 'validation':
                        SweetAlert.swal('Daten fehlerhaft', 'Bitte überprüfen Sie Ihre Daten.', 'error');
                        break;
                    case 'rsvp_not_found':
                    default:
                        SweetAlert.swal('Fehler', 'Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.', 'error');
                        break;
                }
            });
        };

        /**
         * Confirms a rsvp
         *
         * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
         * pass validation.
         *
         * After successful confirmation, the form is reset, a success message is displayed and the 'code' view will
         * be shown next.
         *
         * If an error occurs during the API call an error message is displayed.
         *
         * @param form
         */

        vm.confirmRsvp = function (form) {
            if (!vm._validateRegistrationForm(form)) {
                return;
            }
            vm.submitting = true;
            var status = 'confirmed';
            console.log(status);

            if(vm.eventInformation.pax_limit_information.code.waitlist && vm.eventInformation.pax_limit_information.code.free_slots == 0) {
                status = 'waitlisted';
            }
            AirLSTSdkService.updateRsvpByCodeAndStatus(vm.rsvpCode, status, vm._prepareRsvpInformationForApi(), status).then(function (rsvpInfo) {
                // vm._init();
                vm.submitting = false;

                if (status == 'confirmed') {
                    // SweetAlert.swal('Ihre Zusage', 'Vielen Dank für Ihre Anmeldung zum Event für unsere erfolgreichsten Prohyp-Partner am 16. und 17. September 2019 in Hamburg. In Kürze erhalten Sie die Anmeldebestätigung an Ihre E-Mail-Adresse. ', 'success');
                    vm.setTab('done');
                } else if (status == 'waitlisted') {
                    vm.setTab('done');
                    // SweetAlert.swal('Warteliste', 'Vielen Dank für Ihren Eintrag auf die Warteliste. In Kürze erhalten Sie die Anmeldebestätigung an Ihre E-Mail-Adresse. ', 'success');
                }

                // resets the form
                // https://docs.angularjs.org/api/ng/type/form.FormController#$setPristine
                // form.$setPristine();

                vm.goToView('content');
                vm.setTab('done')
                vm.stage = 0;

            }, function (error) {
                vm.hasError = true;
                vm.submitting = false;

                switch (error.identifier) {
                    case 'validation':
                        SweetAlert.swal('Daten fehlerhaft', 'Bitte überprüfen Sie Ihre Daten.', 'error');
                        break;
                    case 'rsvp_not_found':
                    default:
                        SweetAlert.swal('Fehler', 'Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.', 'error');
                        break;
                }
            });
        };


        /**
         * Confirms a rsvp
         *
         * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
         * pass validation.
         *
         * After successful confirmation, the form is reset, a success message is displayed and the 'code' view will
         * be shown next.
         *
         * If an error occurs during the API call an error message is displayed.
         *
         * @param form
         */


        vm.createRsvp = function (form) {
            if (!vm._validateRegistrationForm(form)) {
                return;
            }
            vm.submitting = true;

            if (!vm.rsvpInformation.hasOwnProperty('rsvp')) {
                vm.rsvpInformation.rsvp = {};
            }

            vm.rsvpInformation.rsvp.status = 'confirmed';

            AirLSTSdkService.submitOpenRequest(vm._prepareRsvpInformationForApi()).then(function (rsvpInfo) {
                // vm._init();
                vm.submitting = false;

                // SweetAlert.swal('Thank you', 'Your data was successfully transmitted. ', 'success');

                // form.$setPristine();
                vm.rsvpInformation.rsvp.status = 'confirmed';
                vm.goToView('content');
                vm.setTab('done');
                vm.stage = 0;

            }, function (error) {
                vm.hasError = true;
                vm.submitting = false;

                switch (error.identifier) {
                    case 'validation':
                        SweetAlert.swal('Daten fehlerhaft', 'Bitte überprüfen Sie Ihre Daten.', 'error');
                        break;
                    case 'rsvp_not_found':
                    default:
                        SweetAlert.swal('Fehler', 'Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.', 'error');
                        break;
                }
            });
        };
         /**
          * Navigates to a different page
          *
          * @param viewToGoTo
          */
         vm.goToView = function(viewToGoTo) {
             vm.currentView = viewToGoTo;

            if (vm.currentView !== 'content') {
                // $document.scrollToElementAnimated(anmeldung);
            }
            $scope.$applyAsync();
        };

        /**
         * Sets answer type
         * Is used as a helper for the landingpage to display different views
         *
         * Possible Values: undifined, cancel, confirmed
         * @param newAnswerType
         */
        vm.setAnswerType = function (newAnswerType) {
            vm.answerType = newAnswerType;
        };


        // Private internal functions

        /**
         * Checks if the current URL contains the rsvp_code GET parameter and tries to load the rsvp by the
         * supplied parameter
         *
         * @private
         */
        vm._checkRsvpCodeOnUrl = function () {
            var match = $location.absUrl().match(/rsvp_code=([A-Za-z0-9]+)/);

            if (match && match.length >= 2 && match[1]) {
                vm.rsvpCode = match[1];
                vm.loadRsvp();
            } else {
                vm.loading = false;
            }
        };

        /** POinti doc */
        vm._checkRsvpActionOnUrl = function () {
            var match = $location.absUrl().match(/action=([A-Za-z0-9]+)/);

            if (match && match.length >= 2 && match[1]) {
                return match[1];
            } else {
                return false;
            }
        };

        /** POinti doc */
        // vm._checkLanguageOnUrl = function () {
        //     var match = $location.absUrl().match(/language=([A-Za-z0-9]+)/);
        //
        //     if (match && match.length >= 2 && match[1]) {
        //         vm.changeLanguage(match[1]);
        //         return;
        //
        //     } else {
        //         return false;
        //     }
        // };

        /**
         *
         * Validates the supplied form
         *
         * @param form
         * @returns {boolean}
         * @private
         */
        vm._validateRegistrationForm = function (form) {
            vm.formValidationReg = true;

            if (!form.$valid) {
                SweetAlert.swal('Fehlende Daten', 'Bitte füllen Sie alle Pflichtfelder aus', 'warning');
                return false;
            } else {
                vm.formValidationReg = false;
                return true;
            }
        };

        // multi step form for REGISTRATION
        vm.stage = "";
        vm.formValidationReg = false;


        /**
         * Adds an empty contact and rsvp object in the guests array
         *
         * This is the place for pre-filling fields in the rsvp, contact, or guest objects *before* the user edits
         * them
         */
        vm.addGuest = function () {
            vm.rsvpInformation.guests.push({
                rsvp: {
                    status: 'invited',
                }
            });
        };

        /**
         * Deletes the given guest object from the guest array
         *
         * @param guest
         */
        vm.removeGuest = function (guest) {
            var index = vm.rsvpInformation.guests.indexOf(guest);

            if (index > -1) {
                vm.rsvpInformation.guests.splice(guest, 1);
            }
        };

        vm.manageGuest = function() {
            if(vm.rsvpInformation.guests.length > 0) {
                vm.removeGuest(vm.rsvpInformation.guests[0]);

            }
            else {
                vm.addGuest();
            }
        };

        /**
         * Populates vm.eventInformation with guestlist data from the server
         *
         * @private
         */


        vm._retrieveEventInformation = function () {
            vm.eventInformation = null;

            AirLSTSdkService.retrieveGuestlistInformation().then(function (eventInformation) {
                vm.eventInformation = eventInformation;
                $scope.$applyAsync();
                vm._prepareCheckInAndOutDate();
            }, function () {
                vm.hasError = true;

                SweetAlert.swal('Fehler', 'Die Informationen zum gewünschten Event konnten nicht geladen werden', 'error');
            });
        };

        vm._prepareCheckInAndOutDate = function () {
            vm.availableCheckInDates = [];
            vm.availableCheckOutDates = [];
        };



        /**
         * Returns rsvp data ready to be submitted
         *
         * @returns {*|null}
         * @private
         */


        vm._prepareRsvpInformationForApi = function () {

            return vm.rsvpInformation;
        };


        /**
         * Transforms rsvp data supplied by the API
         *
         * @param inData
         * @returns {*}
         * @private
         */
        vm._prepareRsvpInformationFromApi = function (inData) {
            vm.companion = inData.guests.length > 0 ? vm.companion = true : vm.companion = false;

            return inData;
        };

        vm._resetRsvpInformation = function () {
            vm.rsvpInformation = null;
        };

        vm._resetRsvpCode = function () {
            vm.rsvpCode = '';
        };

        vm._resetAnswerType = function () {
            vm.answerType = 'undefined';
        };

        vm._initCountDown = function () {
            vm.countdownTime = ((new Date(2020, 0, 31, 14, 0, 0)) - (new Date())) / 1000;
        };


        vm._init();
        vm._checkRsvpCodeOnUrl();
    }]);
